
import { defineComponent, ref, watch } from 'vue'
import MyHeader from '@/components/MyHeader.vue'
import MyBreadCrumb from '@/components/MyBreadCrumb.vue'
import MyFooter from '@/components/MyFooter.vue'
import ScrollReveal from 'scrollreveal'
import { useRouter } from 'vue-router'
import { SOLUTIONS } from '@/utils/database'

export default defineComponent({
  name: 'Solution',
  components: {
    MyHeader,
    MyBreadCrumb,
    MyFooter
  },
  emits: ['return-list'],
  setup() {
    /* -------------从路由中获取当前页面参数----------------- */
    // 实例化路由
    const router = useRouter()
    // 定义页面中需要自动更新的数据变量
    let databaseObj: any = {}
    const bannerData = ref({})
    const planData = ref({})
    const planData2 = ref({})
    const functionData = ref([])
    const musterData = ref({})
    const formationData = ref({})
    const technologyData = ref([])
    const payValueData = ref([])
    const goodnessData = ref([])
    const introData = ref([])
    const specialData = ref({})
    const unifyData = ref([])
    const currentFunctionIndex = ref(0)
    const currentType: any = ref('')
    // 数据初始化
    function initData() {
      const { type } = router.currentRoute.value.query
      currentType.value = type
      databaseObj = SOLUTIONS.find(item => item.name === type) || {}
      bannerData.value = databaseObj.bannerData || {}
      planData.value = databaseObj.planData || {}
      planData2.value = databaseObj.planData2 || {}
      functionData.value = databaseObj.functionData || []
      musterData.value = databaseObj.musterData || {}
      formationData.value = databaseObj.formationData || {}
      introData.value = databaseObj.introData || []
      specialData.value = databaseObj.specialData || {}
      technologyData.value = databaseObj.technologyData || []
      payValueData.value = databaseObj.payValueData || []
      goodnessData.value = databaseObj.goodnessData || []
      unifyData.value = databaseObj.unifyData || []
      currentFunctionIndex.value = 0
    }
    // 路由变化时，刷新数据
    watch(router.currentRoute, () => {
      initData()
    })
    /* -------------从路由中获取当前页面参数End----------------- */

    // 如果是第一次访问页面，初始化数据
    if (JSON.stringify(databaseObj) === '{}') {
      initData()
    }

    return {
      currentType, // 当前方案的key值
      bannerData, // banner模块数据
      planData, // 方案架构模块数据
      planData2, // 方案架构模块数据 -- 特异性处理
      functionData, // 核心功能模块数据
      currentFunctionIndex, // 功能模块当前项
      musterData, // 五位一体模块数据
      formationData, // 组成型模块数据
      technologyData, // 技术特点模块数据
      payValueData, // 交付价值模块数据
      goodnessData, // 优势特点模块数据
      introData, // 功能介绍模块
      unifyData, // 特别介绍模块(建筑)
      specialData // 特别介绍模块(学校)
    }
  },
  // 添加页面滚动加载
  mounted() {
    ScrollReveal().reveal('.plan .left .plan-intro:nth-child(1)', {
      distance: '500px',
      delay: 400,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.plan .left .plan-intro:nth-child(2)', {
      distance: '500px',
      delay: 600,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.plan .left .plan-intro:nth-child(3)', {
      distance: '500px',
      delay: 800,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.plan .left .plan-intro', {
      distance: '500px',
      delay: 400,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.plan .left .custom-value', {
      distance: '500px',
      delay: 600,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.plan .right', {
      distance: '500px',
      delay: 400,
      duration: 1000,
      origin: 'right'
    })
    ScrollReveal().reveal('.function .title-list', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.function .function-list', {
      distance: '500px',
      delay: 400,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.pay-value .pay-value-item:nth-child(odd) .image', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.pay-value .pay-value-item:nth-child(even) .image', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'right'
    })
    ScrollReveal().reveal('.pay-value .pay-value-item:nth-child(odd) .text-container', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'rigth'
    })
    ScrollReveal().reveal('.pay-value .pay-value-item:nth-child(even) .text-container', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.muster .image', {
      distance: '100px',
      delay: 200,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.muster .text', {
      distance: '100px',
      delay: 600,
      duration: 1000,
      origin: 'right'
    })
    ScrollReveal().reveal('.about .card .content', {
      distance: '100px',
      delay: 800,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.formation .formation-list', {
      opacity: 0,
      delay: 200,
      duration: 2000
    })
    ScrollReveal().reveal('.technology .technology-item:nth-child(odd) .image', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'right'
    })
    ScrollReveal().reveal('.technology .technology-item:nth-child(even) .image', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.technology .technology-item:nth-child(odd) .text-container', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.technology .technology-item:nth-child(even) .text-container', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'right'
    })
    ScrollReveal().reveal('.function-intro .function-intro-item:nth-child(odd) .image', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'right'
    })
    ScrollReveal().reveal('.function-intro .function-intro-item:nth-child(even) .image', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.function-intro .function-intro-item:nth-child(odd) .text-container', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'left'
    })
    ScrollReveal().reveal('.function-intro .function-intro-item:nth-child(even) .text-container', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'right'
    })
    ScrollReveal().reveal('.school-special > *', {
      opacity: 0,
      delay: 400,
      duration: 2000
    })
    ScrollReveal().reveal('.building-special .full-container', {
      opacity: 0,
      delay: 400,
      duration: 2000
    })
    ScrollReveal().reveal('.goodness .goodness-list', {
      distance: '500px',
      delay: 400,
      duration: 1000,
      origin: 'bottom'
    })
  }
})
